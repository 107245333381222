import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { EmptyStar } from 'components/Icons'
import Input from 'components/Input'
import SuccessMessage from 'components/SuccessMessage'
import React, { useEffect, useState } from 'react'
import ReviewService from 'services/ReviewService'
import useProduct from 'store/SingleProductProvider'
import { LabelWithInput } from './AddReview.style'

const AddReview = props => {
  const [rating, setRating] = useState(5)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { product } = useProduct()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  const onFormSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    ReviewService.addReview(formData)
      .then(data => {
        setError(false)
        setSuccess(true)
      })
      .catch(() => {
        setSuccess(false)
        setError(true)
      })
  }
  useEffect(() => {
    if (!selectedFile) return
    // create the preview
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }
  return (
    <div className="flex review-modal font-firago mb-4">
      <div className="flex flex-col w-full">
        <div className="flex flex-row choose-rating pb-6">
          <p
            className="flex items-center font-bold text-base "
            data-color="semiblack"
          >
            Choose review:
          </p>
          <div className="stars flex justify-center items-center ml-2">
            {Array(5)
              .fill(1)
              .map((arr, index) => {
                return (
                  <Button
                    className={index <= rating - 1 ? 'active' : ''}
                    onClick={() => setRating(index + 1)}
                    key={index}
                  >
                    <EmptyStar />
                  </Button>
                )
              })}
          </div>
        </div>
        <form className="flex flex-col pt-6" onSubmit={onFormSubmit}>
          <input type="hidden" name="product_id" value={product?.id} />
          <input type="hidden" name="rating" value={rating} />
          <div className="flex flex-col md:flex-row gap-x-4">
            <LabelWithInput className="estimate-name">
              <span>Full Name</span>
              <Input type="text" placeholder="Full Name" name="user_name" />
            </LabelWithInput>
            <LabelWithInput className="estimate-phone">
              <span>Phone Number</span>
              <Input type="number" placeholder="5XX XXX XXX" name="phone" />
            </LabelWithInput>
          </div>

          <LabelWithInput className="estimate-comment mt-4">
            <span>Review </span>
            <textarea placeholder="Write review here" name="message" />
            <ErrorMessage show={error} message="Please enter correctly" />
            <SuccessMessage
              show={success}
              message="Review successfully added"
            />
          </LabelWithInput>

          {preview && (
            <div className="flex justify-center w-full md:w-1/2 h-32 mx-auto my-4">
              <img
                src={preview}
                alt=""
                className="object-fill h-full rounded-sm"
              />
            </div>
          )}
          <div className="flex flex-col md:flex-row justify-between">
            <div className="upload-image-button flex items-center cursor-pointer">
              <input type="file" onChange={onSelectFile} name="photo" />
              {preview ? 'Change photo' : 'Attach photo'}
            </div>
            <Button disabled={success} color="orange">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddReview
