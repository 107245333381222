import { MobileView } from 'components/DeviceDetect'
import CountDown from 'components/Widgets/CountDown'
import React from 'react'
import { Link } from 'react-router-dom'
import useProduct from 'store/SingleProductProvider'
import {
  SpecOfferContainer,
  SpecOfferImage,
  SpecOfferTime,
} from './SpecOffer.style'

const SpecOffer = props => {
  const {
    product,
    product: { landing },
  } = useProduct()

  if (!landing) return null

  return (
    <SpecOfferContainer>
      <SpecOfferImage>
        <img alt="" src="/img/landing_cover.png" />
      </SpecOfferImage>
      <div className="spec-offer-wrapper">
        <Link to="/">
          <img
            width="50"
            height="50"
            src="https://www.cosmo.ge/cosmo.png"
            alt="logo"
            style={{ width: '125px', marginTop: '10px' }}
          />
        </Link>
        <h3 className="my-1 mb-4 spec-offer-text">
          {/* <BrowserView>
            <img alt="" src="/img/sale-percent.svg" />
          </BrowserView> */}
          Special Offer!
        </h3>
        <MobileView>
          <h3 className="product-name"> {product?.name} </h3>
        </MobileView>
      </div>

      {landing.time ? (
        <SpecOfferTime>
          <CountDown date={landing.time} type="landing" />
        </SpecOfferTime>
      ) : null}
    </SpecOfferContainer>
  )
}

export default SpecOffer
