import Button from 'components/Button'
import { BrowserView } from 'components/DeviceDetect'
import * as S from 'components/Header/Header.style'
import Input from 'components/Input'
import React, { useState } from 'react'
import Message from 'components/UI/Message'
import OrdersService from 'services/OrdersService'
import Phone2 from '../../Icons/Phone2'

export default function PhoneBox(props) {
  const [hotLineBoxShow, setHotLineBoxShow] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { number } = props

  const closeHotLine = () => {
    setHotLineBoxShow(false)
    setSubmitted(false)
  }

  const openHotLine = () => {
    setHotLineBoxShow(true)
  }

  const formSubmitted = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    OrdersService.calNeeded(formData).then(data => {
      setSubmitted(true)
    })
  }

  return (
    <BrowserView>
      <S.HotLine onMouseEnter={openHotLine} onMouseLeave={closeHotLine}>
        <NumberInfo number={number} onClick={openHotLine} />
        <S.HotlineBoxWrapper show={hotLineBoxShow}>
          <S.HotLineBox>
            <NumberInfo number={number} onClick={closeHotLine} />
            {submitted ? (
              <Message type="success" message="Order successfully placed" />
            ) : (
              <S.HotLineForm onSubmit={formSubmitted}>
                <div className="flex text-sm flex-col">
                  <div className="flex ">
                    <Input
                      component="radio"
                      name="topic"
                      value="buy_wanted"
                      defaultChecked
                      id="buy_wanted_radio"
                    />
                    <label htmlFor="buy_wanted_radio">Buy Product</label>
                  </div>
                  <div className="flex mt-4">
                    <Input
                      component="radio"
                      name="topic"
                      value="need_help"
                      defaultChecked
                      id="need_help_radio"
                    />
                    <label htmlFor="need_help_radio">Need Help</label>
                  </div>
                </div>

                <h3>Send us your number</h3>
                <S.HotLineInput
                  type="text"
                  name="number"
                  autocomplete="off"
                  placeholder="+995 (---) --- ---"
                />
                <Button type="submit" color="orange">
                  Call me
                </Button>
              </S.HotLineForm>
            )}
          </S.HotLineBox>
        </S.HotlineBoxWrapper>
      </S.HotLine>
    </BrowserView>
  )
}

const NumberInfo = ({ number, onClick }) => {
  return (
    <S.HotLineNumber>
      <Phone2 color="orange" />
      <p>
        <span>Order 10:00-19:00</span>
        {number || ''}
      </p>
    </S.HotLineNumber>
  )
}
