import Button from 'components/Button'
import Modal from 'components/Modal'
import Stars from 'components/Stars'
import { AddReview } from 'components/Widgets/Landing'
import calculatePercent from 'helpers/calculatePercent'
import { Container } from 'layouts/Layout.style'
import React, { useState } from 'react'
import useProduct from 'store/SingleProductProvider'
import { AddReviewContainer } from 'style/Landing.style'
import {
  CommentContainer,
  Comments,
  CommentsHead,
  CommentText,
  ReviewContainer,
  ReviewHeading,
  ReviewPercents,
  PercentsItem,
} from './Review.style'

const Review = props => {
  const [reviewModalShow, setReviewModalShow] = useState(false)
  const {
    product: { reviews: prReview },
  } = useProduct()

  if (!prReview) return null

  const { meta, reviews } = prReview

  return (
    <ReviewContainer className="mb-4">
      <Container>
        <ReviewHeading>
          <h3>Reviews</h3>
          <div className="stars">
            <Stars width="18.29" height="17.45" color="yellow" amount="5" />
            <span>{meta.data.average}/5</span>
          </div>
        </ReviewHeading>

        <ReviewPercents>
          <PercentsItem
            width={calculatePercent(meta?.grouped?.[5] || 0, meta.data.count)}
          >
            <p>5/5</p>
            <p className="percent">
              {calculatePercent(meta?.grouped?.[5] || 0, meta.data.count)} %
            </p>
            <div className="progress-line" />
          </PercentsItem>
          <PercentsItem
            width={calculatePercent(meta?.grouped?.[4] || 0, meta.data.count)}
          >
            <p>4/5</p>
            <p className="percent">
              {calculatePercent(meta?.grouped?.[4] || 0, meta.data.count)} %
            </p>
            <div className="progress-line" />
          </PercentsItem>
          <PercentsItem
            width={calculatePercent(meta?.grouped?.[3] || 0, meta.data.count)}
          >
            <p>3/5</p>
            <p className="percent">
              {calculatePercent(meta?.grouped?.[3] || 0, meta.data.count)} %
            </p>
            <div className="progress-line" />
          </PercentsItem>
          <PercentsItem
            width={calculatePercent(meta?.grouped?.[2] || 0, meta.data.count)}
          >
            <p>2/5</p>
            <p className="percent">
              {calculatePercent(meta?.grouped?.[2] || 0, meta.data.count)} %
            </p>
            <div className="progress-line" />
          </PercentsItem>
          <PercentsItem
            width={calculatePercent(meta?.grouped?.[1] || 0, meta.data.count)}
          >
            <p>1/5</p>
            <p className="percent">
              {calculatePercent(meta?.grouped?.[1] || 0, meta.data.count)} %
            </p>
            <div className="progress-line" />
          </PercentsItem>
        </ReviewPercents>

        <CommentContainer>
          {reviews.map(review => (
            <Comments>
              <CommentsHead>
                <img src={review.image} alt="" />
                <h2> {review.user_name || ''} </h2>
                <p>Date: {review.date}</p>
                <div className="stars">
                  <Stars color="yellow" amount={review.rating} />
                  <span>Review {review.rating}/5</span>
                </div>
              </CommentsHead>
              <CommentText>{review.message || ''}</CommentText>
            </Comments>
          ))}
          <Button
            color="orange"
            className="add-review"
            onClick={() => setReviewModalShow(true)}
          >
            Write Review
          </Button>
          <Modal
            title="Write Review"
            show={reviewModalShow}
            onClose={() => setReviewModalShow(false)}
          >
            <AddReviewContainer>
              <AddReview />
            </AddReviewContainer>
          </Modal>
        </CommentContainer>
      </Container>
    </ReviewContainer>
  )
}

export default Review
