import { BrowserView, MobileAndTabletView } from 'components/DeviceDetect'
import * as S from 'components/Header/Header.style'
import Stars from 'components/Stars'
import categoryUrl from 'helpers/categoryUrl'
import searchUrl from 'helpers/searchUrl'
import queryString from 'query-string'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import SearchService from 'services/SearchService'
import detectOutside from 'helpers/detectOutside'
import {
  Autocomplete,
  AutocompleteHeading,
  Categories,
  CategoryItem,
  Keywords,
  KeywordsItem,
  Suggestions,
  SuggestionsItem,
  SeeAll,
} from './SearchBox.style'

export const SearchAutoComplete = props => {
  const { result, keyword, onKeywordChoose, onClick } = props

  const searchProductChosen = product_id => {
    SearchService.searchProductChosen(result.search_id, product_id)
  }

  return (
    <Fragment>
      <Autocomplete>
        {result.products.length ? (
          <Suggestions>
            <AutocompleteHeading>Search Results</AutocompleteHeading>
            {result.products.map(product => (
              <SuggestionsItem
                className=""
                onClick={() => {
                  onClick()
                  searchProductChosen(product.id)
                }}
                to={`/product/${product.slug || ''}`}
              >
                <img
                  className="search-product-image"
                  src={product.image}
                  alt={product.name}
                />
                <div className="item-info">
                  <h2>{product.name}</h2>
                  {product.price ? <p>{product.price}₾</p> : 'Out Of Stock '}
                  <BrowserView>
                    <Stars amount={Math.round(product.rating / 2)} />
                  </BrowserView>
                </div>
              </SuggestionsItem>
            ))}
          </Suggestions>
        ) : null}
        {result.suggestions.length ? (
          <Keywords>
            <AutocompleteHeading>Search Keywords</AutocompleteHeading>
            {(result.suggestions || []).map(s => {
              return (
                <KeywordsItem
                  key={s.id}
                  onClick={() => {
                    onKeywordChoose(s.text)
                    onClick()
                  }}
                >
                  {s.text}
                </KeywordsItem>
              )
            })}
          </Keywords>
        ) : null}
        {result.categories.length ? (
          <Categories>
            <AutocompleteHeading>Categories</AutocompleteHeading>
            {(result.categories || []).map(s => {
              return (
                <CategoryItem
                  key={s.id}
                  to={categoryUrl(s.slug, s.count === '0' ? null : keyword)}
                >
                  {s.title}
                  {s.count ? `(${s.count})` : null}
                </CategoryItem>
              )
            })}
          </Categories>
        ) : null}
        {result.notfound && (
          <Categories>
            <AutocompleteHeading>Nothing Found</AutocompleteHeading>
          </Categories>
        )}
        {!result.notfound ? (
          <SeeAll onClick={props.onSeeAll}>See all</SeeAll>
        ) : null}
      </Autocomplete>
    </Fragment>
  )
}

export default function SearchBox(props) {
  const history = useHistory()
  const { keyword: keywordParam } = queryString.parse(
    typeof window != 'undefined' ? window.location.search : ''
  )
  const SearchRef = useRef(null)
  const [search, setSearchValue] = useState('')
  const [result, setResult] = useState({})
  const [focusActive, setFocusActive] = useState(false)
  const { type } = props

  useEffect(() => {
    const deb = setTimeout(() => {
      if (!search) return
      SearchService.search(search).then(res => {
        setResult(res)
      })
    }, 300)

    return () => clearTimeout(deb)
  }, [search])

  const handleKeyPress = e => {
    if (e.key !== 'Enter') return
    history.push(searchUrl(search))
  }

  const keywordChosen = k => {
    setSearchValue(k)
    history.push(searchUrl(k))
  }

  useEffect(() => {
    if (keywordParam !== undefined) setSearchValue(keywordParam)
  }, [keywordParam])

  const handleBlur = () => {
    setTimeout(() => setFocusActive(false), 500)
  }

  detectOutside(SearchRef, handleBlur)

  const SearchComponent = (
    <S.HeaderSearch
      component="search"
      placeholder={'Search here...'}
      ref={SearchRef}
      value={search}
      onFocus={() => setFocusActive(true)}
      onKeyPress={handleKeyPress}
      name="search"
      onChange={e => setSearchValue(e.target.value)}
      buttonProps={{
        onClick: () => {
          history.push(searchUrl(search))
        },
      }}
      suggestion={
        focusActive && Object.keys(result).length ? (
          <Fragment>
            <SearchAutoComplete
              keyword={search}
              onClick={handleBlur}
              onKeywordChoose={keywordChosen}
              onSeeAll={() => history.push(searchUrl(search))}
              result={result}
            />
          </Fragment>
        ) : null
      }
    />
  )

  if (type === 'mobile') {
    return <MobileAndTabletView>{SearchComponent}</MobileAndTabletView>
  } else {
    return <BrowserView>{SearchComponent}</BrowserView>
  }
}
