import React, { useState, useRef } from 'react'
import Button from 'components/Button'
import { Basket, Close } from 'components/Icons'
import getProductUrl from 'helpers/getProductUrl'
import useCart from 'store/CartProvider'
import * as S from '../Header.style'
import detectOutside from 'helpers/detectOutside'
import { useHistory } from 'react-router'

export const Cart = props => {
  const [open, setOpen] = useState(false)
  const { products, deleteProduct } = useCart()
  const history = useHistory()

  const handleOpen = e => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const BasketRef = useRef(null)
  detectOutside(BasketRef, handleClose)
  return (
    <div ref={BasketRef}>
      <S.HeaderBasketIcon onClick={handleOpen} className="cursor-pointer">
        <Basket width="25" height="25" />
        <span>{products.length}</span>
      </S.HeaderBasketIcon>

      <S.HeaderCartBoxWrapper show={open}>
        <S.HeaderCartBox>
          {!products.length ? (
            <div>
              <h3 style={{ padding: '0 20px', margin: 0, width: '100%' }}>
                Cart Is Empty
              </h3>
              <h2 style={{ padding: '0 20px', margin: 0, width: '100%' }}>
                Please choose products
              </h2>
            </div>
          ) : (
            <S.HeaderBoxItems
              style={{ textAlign: products.length ? 'unset' : 'center' }}
            >
              {products.map(c => (
                <S.HeaderCartBoxItem to={getProductUrl(c.slug)}>
                  <img className="lazyload" data-src={c.image} alt="" />
                  <S.HeaderCartBoxItemInfo>
                    <h3>{c.name}</h3>
                    <p>
                      {c.quantity} x {c.price}₾
                    </p>
                  </S.HeaderCartBoxItemInfo>
                  <Button
                    className="delete-button "
                    onClick={e => {
                      e.preventDefault()
                      deleteProduct(c.sku)
                    }}
                  >
                    <Close width="12" height="12" />
                  </Button>
                </S.HeaderCartBoxItem>
              ))}
            </S.HeaderBoxItems>
          )}

          <S.HeaderCartBoxActions>
            {products.length ? (
              <Button color="orange" onClick={() => history.push('/purchase')}>
                Buy
              </Button>
            ) : null}
          </S.HeaderCartBoxActions>
        </S.HeaderCartBox>
      </S.HeaderCartBoxWrapper>
    </div>
  )
}
