import { BrowserView } from 'components/DeviceDetect'
import { Phone } from 'components/Icons'
import { HalfContainer } from 'layouts/Layout.style'
import React from 'react'
import { Link } from 'react-router-dom'
import useConfig from 'store/ConfigProvider'
import {
  FooterContainer,
  FooterGooGleMapContent,
  FooterInfoContainer,
  FooterInfoContent,
  FooterSocialContent,
} from './Footer.style'

const Footer = props => {
  const { settings } = useConfig()

  return (
    <FooterContainer>
      <FooterInfoContainer>
        <FooterInfoContent>
          <HalfContainer>
            <Link to="/">
              <img
                alt=""
                width="50"
                height="50"
                src="https://www.cosmo.ge/cosmo.png"
              />
            </Link>
            <a className="call" href={`tel:${settings.phone_number}`}>
              <i>
                <Phone color="orange" />
              </i>
              <span>Contact Us</span>
              <span className="phone-number">{settings.phone_number}</span>
            </a>

            <p>Address</p>
            <address>
              Vazha Pshavela ave. #16a,
              <br />
              Tbilisi, Georgia 0192
            </address>
          </HalfContainer>
        </FooterInfoContent>
        <FooterGooGleMapContent>
          <img
            src={settings.landing_map || ''}
            className="w-full h-full object-fill"
            alt=""
          />
        </FooterGooGleMapContent>
        <FooterSocialContent>
          <HalfContainer>
            {settings.facebook_url && (
              <a
                className="social"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.facebook_url}
              >
                <img alt="" src="/img/facebook.svg" />
              </a>
            )}
            {settings.youtube_url && (
              <a
                className="social"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.youtube_url}
              >
                <img alt="" src="/img/youtube.svg" />
              </a>
            )}
            {settings.instagram_url && (
              <a
                className="social"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.instagram_url}
              >
                <img alt="" src="/img/instagram.svg" />
              </a>
            )}
          </HalfContainer>
        </FooterSocialContent>
      </FooterInfoContainer>
      <BrowserView>
        <FooterGooGleMapContent className="desktop-map">
          <img
            src={settings.landing_map || ''}
            className="w-full h-full object-fill"
            alt=""
          />
        </FooterGooGleMapContent>
      </BrowserView>
    </FooterContainer>
  )
}

export default Footer
